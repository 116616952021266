
<template>
    <div class="pb-2">
        <div elevation="0" style="padding: 20px 3px">
            <v-card-subtitle class="px-0 mb-1 mt-1 py-0">Dein Badge Guthaben</v-card-subtitle>
            <v-card-title class="pa-0 h2 font-weight-bold">
                CHF
                <number class="pl-2" :to="formattedTransactionBalance[0]"></number>
                <span style="opacity: 0.5">
                    .{{
                        formattedTransactionBalance[1].length === 1
                            ? formattedTransactionBalance[1] + '0'
                            : formattedTransactionBalance[1]
                    }}
                </span>
            </v-card-title>
        </div>
        <v-card elevation="1" class="gradient-background mt-0">
            <v-card-subtitle class="px-0 py-0 white--text">
                {{ activeContract.start + ' - ' }}
                {{
                    activeContract.timeStopBonus.length
                        ? activeContract.timeStopBonus[activeContract.timeStopBonus.length - 1].end
                        : activeContract.bonus.length
                        ? activeContract.bonus[activeContract.bonus.length - 1].end
                        : activeContract.end
                }}
            </v-card-subtitle>
            <v-card-title class="pa-0 font-weight-bold">{{ activeContract.title }}</v-card-title>
        </v-card>
        <v-card v-if="showTimestopButton" class="options-card" elevation="1">
            <v-card-title class="font-weight-bold h6 mb-0" style="color: #313131; letter-spacing: 0.2px">
                <v-icon class="pr-2" color="primary" small>mdi-layers</v-icon>
                Shortcuts
            </v-card-title>
            <v-row no-gutters class="px-3">
                <v-col cols="3" class="px-1 pb-3 text-center align-center d-flex flex-column">
                    <v-btn
                        height="45px"
                        width="45px"
                        class="elevation-1"
                        @click="timestopActive = true"
                        fab
                        color="white"
                    >
                        <v-icon color="primary">mdi-timer-pause-outline</v-icon>
                    </v-btn>
                    <span class="my-1" style="color: #313131; line-height: 1.2; font-size: 13px">Abo pausieren</span>
                </v-col>
                <v-col v-if="false" cols="3" class="px-1 pb-3 text-center align-center d-flex flex-column">
                    <v-btn
                        height="45px"
                        width="45px"
                        class="elevation-1"
                        @click="healthcareActive = true"
                        fab
                        color="white"
                    >
                        <v-icon color="primary">mdi-medication-outline</v-icon>
                    </v-btn>
                    <span class="my-1" style="color: #313131; line-height: 1.2; font-size: 13px">
                        KK
                        <br />
                        Bestätigung
                    </span>
                </v-col>
            </v-row>
        </v-card>
        <v-row no-gutters>
            <v-col class="d-flex flex-row justify-space-between" cols="12">
                <v-card-title class="px-0">Transaktionen</v-card-title>

                <v-switch hide-details class="mr-2 my-0 pt-0 align-center" v-model="showAllPayments">
                    <template #prepend>
                        <v-label>
                            <span style="width: 80px; margin-top: -2px; margin-right: 2px" class="d-flex justify-end">
                                <v-chip>{{ showAllPayments ? 'bezahlt' : 'offen' }}</v-chip>
                            </span>
                        </v-label>
                    </template>
                </v-switch>
            </v-col>
            <v-alert class="ma-auto" v-if="!transactionsMapped || !transactionsMapped.length" type="info">
                {{ showAllPayments ? 'Keine Zahlungen gefunden' : 'Keine offenen Rechnungen' }}
            </v-alert>

            <v-col class="my-2 px-1" cols="12" v-for="(payment, i) of transactionsMapped" :key="i + '-paymentIndex'">
                <v-card class="pa-3 rounded-xl">
                    <v-row no-gutters>
                        <v-col cols="2" class="align-content-center">
                            <v-img
                                :src="
                                    getImgForTransactions(
                                        payment.type === 'Mitgliedschaft' ? payment.type : payment.Type
                                    )
                                "
                            ></v-img>
                        </v-col>
                        <v-col cols="10">
                            <div class="ml-3">
                                <v-chip
                                    class="mr-1"
                                    small
                                    v-if="
                                        !(payment.Type === 'OPEN_PAYMENT' && payment.MainType === 'RESULTING_PAYMENTS')
                                    "
                                >
                                    {{
                                        payment.AccountTransactionDetails
                                            ? $moment(payment.TransactionDate).format('DD MMM YYYY')
                                            : $moment(payment.TransactionDate)
                                                  .subtract(2, 'hours')
                                                  .format('DD MMM YYYY HH:mm')
                                    }}
                                </v-chip>
                                <v-chip
                                    v-if="payment.AccountTransactionDetails"
                                    class="white--text mr-1"
                                    small
                                    :color="getChipTypeColor(payment)"
                                >
                                    {{ getChipTypeText(payment) }}
                                </v-chip>
                            </div>
                            <v-card-title
                                class="h6 pt-1 pb-2 font-weight-bold"
                                v-if="payment.AccountTransactionDetails"
                            >
                                {{
                                    getMembershipPaymentDescription(
                                        payment.AccountTransactionDetails.AccountTransactionDetail[0].Description
                                    )
                                }}
                            </v-card-title>
                            <v-card-title class="h6 pt-1 pb-2 font-weight-bold" v-else>
                                {{ getPaymentDescription(payment.Description) }}
                            </v-card-title>
                            <v-card-subtitle class="pb-0">
                                <!--

                                  Type: {{ payment.Type }}
                                  <br />
                                  mainType: {{ payment.MainType }}
                                  <br />
                                  type: {{ payment.type }}
                                  <br />
                                  
                                  details: {{ payment.AccountTransactionDetails.AccountTransactionDetail }}
                                -->
                                <span
                                    v-if="
                                        payment.AccountTransactionDetails &&
                                        payment.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange
                                    "
                                >
                                    {{
                                        'Laufzeit: ' +
                                        $moment(
                                            payment.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange
                                                .Start,
                                            'YYYY-MM-DD'
                                        ).format('DD.MM.YYYY') +
                                        ' - ' +
                                        $moment(
                                            payment.AccountTransactionDetails.AccountTransactionDetail[0].TimeRange.End,
                                            'YYYY-MM-DD'
                                        ).format('DD.MM.YYYY')
                                    }}
                                    <br />
                                </span>
                                <span
                                    v-if="payment.Value"
                                    :class="{
                                        'red--text': payment.Value.value < 0 || payment.Type === 'OPEN_PAYMENT',
                                        'green--text': payment.Value.value > 0 && !payment.Type === 'OPEN_PAYMENT',
                                    }"
                                >
                                    {{ payment.Value.value + ' ' + payment.Value.currencyCode }}
                                </span>
                            </v-card-subtitle>
                            <v-btn
                                v-if="
                                    (false &&
                                        isSeb &&
                                        payment.Type === 'OPEN_PAYMENT' &&
                                        payment.MainType === 'RESULTING_PAYMENTS') ||
                                    (payment.State === 'OPEN' && isSeb)
                                "
                                @click="initPaymentForTransaction(payment)"
                                x-small
                                absolute
                                style="bottom: 0; right: 0"
                                text
                            >
                                JETZT ZAHLEN
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="timestopActive" persistent fullscreen>
            <user-time-stop :user="user" @close-view="closingView()"></user-time-stop>
        </v-dialog>
        <user-healthcare
            v-if="false"
            @closeForm="healthcareActive = false"
            :showDialog="healthcareActive"
        ></user-healthcare>
    </div>
</template> 

<script>
import api from '@/api'
import UserTimeStop from '@/views/user/views/UserTimestop.vue'
import UserHealthcare from '@/views/user/views/UserHealthcare.vue'
import { formatCurrency } from '@/moduls/currency/currency.js'
import userService from '../../../services/userService'

export default {
    props: {
        user: {
            type: Object,
        },
        contractInformations: {
            type: Object,
        },
        transactions: {},
    },
    components: {
        UserTimeStop,
        UserHealthcare,
    },
    data() {
        return {
            showAllPayments: false,
            userService,
            healthcareActive: false,
            timestopActive: false,
        }
    },
    async created() {
        console.log('da hani jetzt bechooo', this.transactions)

        //let infos = await api.fetchPackageUpgradePossibilitesForOwnCustomer()
    },
    methods: {
        test() {},
        formatCurrency1(value) {
            return formatCurrency(value)
        },
        closingView() {
            this.timestopActive = false
        },
        bonusCalculatedFromMultipleTimespans(allTimeSpans, timeSpanDays) {
            const totalDays = allTimeSpans.reduce((total, timeSpan) => {
                return total + this.daysInTimeSpan(timeSpan)
            }, 0)
            return (
                Number(totalDays) === Number(timeSpanDays) ||
                Number(totalDays) - 1 === Number(timeSpanDays) ||
                Number(totalDays) + 1 === Number(timeSpanDays)
            )
        },
        daysInTimeSpan(timeSpan) {
            const start = this.$moment(timeSpan.TimeSpan.Start, 'YYYY-MM-DD')
            const end = this.$moment(timeSpan.TimeSpan.End, 'YYYY-MM-DD')
            return end.diff(start, 'days')
        },
        getMembershipPaymentDescription(description) {
            if (description.toString() === 'Mahngebühren 2 Umsatz') return '2. Mahnung'
            if (description.toString() === 'Mahngebühren 3 Umsatz') return '3. Mahnung'
            if (description.toString() === 'Mahngebühren') return '3. Mahnung'
            return description
        },
        getPaymentDescription(description) {
            return description.replace('/Fr. d+(.d{2})?$/', '').trim()
        },
        getChipTypeColor(payment) {
            if (
                payment.Type === 'OPEN_PAYMENT' &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'PAID'
            )
                return 'green'
            if (
                payment.Type === 'OPEN_PAYMENT' &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State !== 'PAID'
            )
                return 'orange'
            if (payment.Type === 'OPEN_PAYMENT' && payment.MainType === 'RESULTING_PAYMENTS') return 'grey'
            if (payment.Type === 'PAYMENT' && payment.MainType === 'PAYMENTS_AND_TRANSACTIONS') return 'green'
            if (
                (payment.Type === 'FEE' || payment.Type === 'IDLE_PERIOD') &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'OPEN'
            )
                return 'red'
            if (
                (payment.Type === 'FEE' || payment.Type === 'IDLE_PERIOD') &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'PAID'
            )
                return 'green'
        },
        getChipTypeText(payment) {
            if (
                payment.Type === 'OPEN_PAYMENT' &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'PAID'
            )
                return 'bezahlt'
            if (
                payment.Type === 'OPEN_PAYMENT' &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State !== 'PAID'
            )
                return 'offen'
            if (payment.Type === 'OPEN_PAYMENT' && payment.MainType === 'RESULTING_PAYMENTS')
                return (
                    'fällig bis: ' +
                    this.$moment(payment.AccountTransactionDetails.AccountTransactionDetail[0].DueDate).format(
                        'DD. MMM YYYY'
                    )
                )
            if (
                (payment.Type === 'FEE' || payment.Type === 'IDLE_PERIOD') &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'OPEN'
            )
                return 'offene Gebühr'
            if (
                (payment.Type === 'FEE' || payment.Type === 'IDLE_PERIOD') &&
                payment.MainType === 'PAYMENTS_AND_TRANSACTIONS' &&
                payment.State === 'PAID'
            )
                return 'bezahlte Gebühr'
            if (payment.Type === 'PAYMENT' && payment.MainType === 'PAYMENTS_AND_TRANSACTIONS') return 'Zahlung'
        },
        getImgForTransactions(type) {
            let transMapper = {
                Mitgliedschaft: '/img/lottiefiles/transactions/typeMembership.png',
                CREDIT: '/img/lottiefiles/transactions/typeCredit.png',
                DEBIT: '/img/lottiefiles/transactions/typePurchase.png',
            }
            return transMapper[type]
        },
    },
    computed: {
        transactionsMapped() {
            let transactionsToUse = JSON.parse(JSON.stringify(this.combinedArray))
            if (!this.showAllPayments) {
                transactionsToUse = transactionsToUse.filter((trans) => {
                    return (
                        (trans.Type === 'OPEN_PAYMENT' && trans.MainType === 'RESULTING_PAYMENTS') ||
                        trans.State === 'OPEN'
                    )
                })
            } else {
                transactionsToUse = transactionsToUse.filter((trans) => {
                    return (
                        !(trans.Type === 'OPEN_PAYMENT' && trans.MainType === 'RESULTING_PAYMENTS') &&
                        trans.State !== 'OPEN'
                    )
                })
            }

            return transactionsToUse.sort((a, b) => {
                if (!this.showAllPayments) {
                    return new Date(a.TransactionDate) - new Date(b.TransactionDate)
                }
                return new Date(b.TransactionDate) - new Date(a.TransactionDate)
            })
        },
        combinedArray() {
            if (!this.transactions.membershipAccountTransactions) this.transactions.membershipAccountTransactions = []
            if (!this.transactions.secondarySpendAccountTransactions)
                this.transactions.secondarySpendAccountTransactions = []
            return [
                ...this.transactions.membershipAccountTransactions
                    .map((item) => ({ ...item, type: 'Mitgliedschaft' }))
                    .filter(
                        (payment) => !(payment.Type === 'PAYMENT' && payment.MainType === 'PAYMENTS_AND_TRANSACTIONS')
                    ),
                ...this.transactions.secondarySpendAccountTransactions.map((item) => ({ ...item, type: 'Einkauf' })),
            ]
        },

        formattedTransactionBalance() {
            if (!this.transactions.balance) return [0, 0]
            return [this.transactions.balance.split('.')[0], this.transactions.balance.split('.')[1]]
        },
        showTimestopButton() {
            return true
        },

        activeContract() {
            if (!this.contractInformations) return []
            let timeSpans = this.contractInformations.ContractTermTimes.ContractTermTime
            // console.log('before', this.fixTermTimesMissingBefore)
            //console.log('after', this.fixTermTimesMissingAfter)
            //  console.log('am schluss gits: ', timeSpans)
            let activeContractDate = JSON.parse(JSON.stringify(timeSpans))
                .reverse()
                .find(
                    (ts) =>
                        ts.Type === 'DEFAULT' &&
                        !this.$moment(ts.TimeSpan.Start, 'YYYY-MM-DD').isAfter(this.$moment(), 'day')
                ).TimeSpan.Start

            let contracts = []
            let newContract = null
            let idlePeriodTimeSpans = timeSpans.filter((timespan) => timespan.Type === 'IDLEPERIOD')
            timeSpans.forEach((timespan) => {
                let filteredTimeSpans = JSON.parse(JSON.stringify(idlePeriodTimeSpans))
                if (newContract) {
                    filteredTimeSpans = filteredTimeSpans.filter((filterTimeSpan) => {
                        return this.$moment(filterTimeSpan.TimeSpan.Start).isSameOrAfter(
                            this.$moment(newContract.start, 'DD.MM.YYYY')
                        )
                    })
                }
                if (timespan.Type === 'DEFAULT') {
                    if (
                        newContract &&
                        idlePeriodTimeSpans.length &&
                        (idlePeriodTimeSpans.find((idleTimeSpan) => {
                            return this.daysInTimeSpan(idleTimeSpan) === this.daysInTimeSpan(timespan)
                        }) ||
                            this.bonusCalculatedFromMultipleTimespans(filteredTimeSpans, this.daysInTimeSpan(timespan)))
                    ) {
                        newContract.timeStopBonus.push({
                            start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                            end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                        })
                    } else {
                        if (newContract !== null) contracts.push(newContract)
                        newContract = {
                            ContractID: timespan.ContractID.UniqueID[0].value,
                            title: this.contractInformations.MemberCategoryName,
                            start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                            end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                            bonus: [],
                            timeStopBonus: [],
                            idlePeriods: [],

                            active: activeContractDate === timespan.TimeSpan.Start,
                            future: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').isAfter(this.$moment()),
                        }
                    }
                }
                if (timespan.Type === 'BONUSPERIOD') {
                    newContract.bonus.push({
                        start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                        end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    })
                }
            })

            contracts.push(newContract)
            idlePeriodTimeSpans.forEach((timespan) => {
                let foundContract = contracts.find((cont) => {
                    let contEndDate = cont.timeStopBonus.length
                        ? cont.timeStopBonus[cont.timeStopBonus.length - 1].end
                        : cont.bonus.length
                        ? cont.bonus[cont.bonus.length - 1].end
                        : cont.end
                    return this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').isBetween(
                        this.$moment(cont.start, 'DD.MM.YYYY'),
                        this.$moment(contEndDate, 'DD.MM.YYYY')
                    )
                })

                if (!foundContract) return

                foundContract.idlePeriods.push({
                    start: this.$moment(timespan.TimeSpan.Start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    end: this.$moment(timespan.TimeSpan.End, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                })
            })
            if (!contracts.length && newContract && newContract.title) contracts.push(newContract)
            if (!contracts.find((cont) => cont.active && !cont.future)) contracts[contracts.length - 1].active = true
            return contracts.find((cont) => cont.active)
        },
    },
}
</script>
<style lang="scss" scoped>
.gradient-background {
    background: linear-gradient(135deg, rgba(0, 150, 63, 1) 0%, rgba(0, 150, 63, 0.7) 50%, rgba(0, 150, 63, 0.4) 100%);
    border-radius: 15px;
    padding: 20px;
    color: white;
}
.options-card {
    margin-top: 20px;
    background: #eff0f2;
    border-radius: 15px;
    color: white;
}
</style>